import Vue from 'vue'
import Vuex from 'vuex'

Vue.use(Vuex)

export default new Vuex.Store({
  state: {
    infoUser: 0,
    roleName: ""
  },
  mutations: {
    setInfoUser(state, value) {
      state.infoUser = value;
    },
    setRoleName(state, value) {
      state.roleName = value;
    },
  },
  actions: {
  },
  modules: {
  },
  getters: {
    getInfoUser(state){
      return state.infoUser;
    },
    getRoleName(state){
      return state.roleName;
    }

  }
})
