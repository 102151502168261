import Vue from "vue";
import VueRouter from "vue-router";
import Home from "../views/Home.vue";

Vue.use(VueRouter);

const routes = [
  { path: "*", component: () => import("@/views/others/404.vue") },
  {
    path: "/",
    component: () => import("@/views/auth/_Layout.vue"),
    meta: { isPublic: true },
    redirect: { name: "login" },
    children: [
      {
        path: "/unauthorized",
        name: "unauthorized",
        meta: {
          title: "Autocom sin atorización",
          descriptionLayout: "Sin autorización",
          role: 5,
        },
        component: () => import("@/views/others/Unauthorized.vue"),
      },
      {
        path: "login/validate",
        name: "loginValidate",
        meta: {
          title: "Autocom login",
          descriptionLayout: "Inicia sesión",
          role: 5,
        },
        component: () => import("@/views/others/Login.vue"),
      },
      {
        path: "login",
        name: "login",
        meta: {
          title: "Autocom login",
          descriptionLayout: "Inicia sesión",
          role: 5,
        },
        component: () => import("@/views/auth/Login.vue"),
      },
      // {
      //   path: "recuperar",
      //   name: "recover",
      //   meta: {
      //     title: "Autocom recuperar",
      //     descriptionLayout: "Recuperar contraseña",
      //     role: 5,
      //   },
      //   component: () => import("@/views/auth/Recover.vue"),
      // },
      {
        path: "cambiar/contrasena/token/:token",
        name: "resetPassword",
        meta: {
          title: "Autocom recuperar",
          descriptionLayout: "Recuperar contraseña",
          role: 5,
        },
        component: () => import("@/views/auth/Reset.vue"),
      },
    ],
  },
  {
    path: "/workforce",
    component: () => import("@/views/user/_Layout.vue"),
    meta: { isPublic: false },
    role: 4,
    redirect: { name: "listCampaign" },
    children: [
      {
        path: "campana",
        name: "listCampaign",
        meta: {
          title: "Autocom campaña",
          descriptionLayout: "Listar campañas",
        },
        component: () => import("@/views/user/workforce/Campaign.vue"),
      },
      {
        path: "campana/:campaignId/comision/add",
        name: "commissionsAdd",
        meta: {
          title: "Autocom comisión",
          descriptionLayout: "Agregar comisión",
        },
        component: () => import("@/views/user/workforce/AddCommission.vue"),
      },
      {
        path: "comision",
        name: "commissions",
        meta: {
          title: "Autocom comisiones",
          descriptionLayout: "Listar comisiones",
        },
        component: () => import("@/views/user/workforce/Commission.vue"),
      },
      {
        path: "comision/:commissionId/details",
        name: "commissionDetails",
        meta: {
          title: "Autocom comisiones",
          descriptionLayout: "Listar comisiones",
        },
        component: () => import("@/views/user/workforce/Details.vue"),
      },
      {
        path: "actividad",
        name: "activity",
        meta: { title: "Autocom actividad", descriptionLayout: "Actividad" },
        component: () => import("@/views/user/workforce/Activity.vue"),
      },
      {
        path: "clientes",
        name: "configClient",
        meta: { title: "Autocom configuración de clientes", descriptionLayout: "Autocom configuración de clientes" },
        component: () => import("@/views/user/workforce/ConfigClient.vue"),
      },
      {
        path: "usuarios",
        name: "addUser",
        meta: {
          title: "Autocom workforce",
          descriptionLayout: "Agregar usuarios",
        },
        component: () => import("@/views/user/workforce/AddUser.vue"),
      },
      // {
      //   path: "cambiar/contrasena",
      //   name: "changePassword",
      //   meta: {
      //     title: "Autocom cambiar contraseña",
      //     descriptionLayout: "Cambiar contraseña",
      //   },
      //   component: () => import("@/views/user/ResetPassword.vue"),
      // },
    ],
  },
  {
    path: "/director",
    component: () => import("@/views/user/_Layout.vue"),
    meta: { isPublic: false },
    role: 4,
    redirect: { name: "commissionsDirectory" },
    children: [
      {
        path: "comision",
        name: "commissionsDirectory",
        meta: {
          title: "Autocom comisiones",
          descriptionLayout: "Listar comisiones",
        },
        component: () => import("@/views/user/directory/Commission.vue"),
      },
      {
        path: "comision/:commissionId/details",
        name: "commissionDetailsDirectory",
        meta: {
          title: "Autocom comisiones",
          descriptionLayout: "Listar comisiones",
        },
        component: () => import("@/views/user/directory/Details.vue"),
      },
      // {
      //   path: "cambiar/contrasena",
      //   name: "changePassword1",
      //   meta: {
      //     title: "Autocom cambiar contraseña",
      //     descriptionLayout: "Cambiar contraseña",
      //   },
      //   component: () => import("@/views/user/ResetPassword.vue"),
      // },
    ],
  },
  {
    path: "/gerente",
    component: () => import("@/views/user/_Layout.vue"),
    meta: { isPublic: false },
    role: 4,
    redirect: { name: "commissionsManager" },
    children: [
      {
        path: "comision",
        name: "commissionsManager",
        meta: {
          title: "Autocom comisiones",
          descriptionLayout: "Listar comisiones",
        },
        component: () => import("@/views/user/manager/Commission.vue"),
      },
      {
        path: "comision/:commissionId/details",
        name: "commissionDetailsManager",
        meta: {
          title: "Autocom comisiones",
          descriptionLayout: "Listar comisiones",
        },
        component: () => import("@/views/user/manager/Details.vue"),
      },
      // {
      //   path: "cambiar/contrasena",
      //   name: "changePassword2",
      //   meta: {
      //     title: "Autocom cambiar contraseña",
      //     descriptionLayout: "Cambiar contraseña",
      //   },
      //   component: () => import("@/views/user/ResetPassword.vue"),
      // },
    ],
  },
    {
    path: "/gerentenomina",
    component: () => import("@/views/user/_Layout.vue"),
    meta: { isPublic: false },
    role: 4,
    redirect: { name: "commissionsPayRollManager" },
    children: [
      {
        path: "comision",
        name: "commissionsPayRollManager",
        meta: {
          title: "Autocom comisiones",
          descriptionLayout: "Listar comisiones",
        },
        component: () => import("@/views/user/payRollManager/Commission.vue"),
      },
      {
        path: "comision/:commissionId/details",
        name: "commissionDetailsPayRollManager",
        meta: {
          title: "Autocom comisiones",
          descriptionLayout: "Listar comisiones",
        },
        component: () => import("@/views/user/payRollManager/Details.vue"),
      },
      // {
      //   path: "cambiar/contrasena",
      //   name: "changePassword3",
      //   meta: {
      //     title: "Autocom cambiar contraseña",
      //     descriptionLayout: "Cambiar contraseña",
      //   },
      //   component: () => import("@/views/user/ResetPassword.vue"),
      // },
    ],
  },
  // Coordinador
  {
    path: "/coordinador",
    component: () => import("@/views/user/_Layout.vue"),
    meta: { isPublic: false },
    role: 4,
    redirect: { name: "commissionsCoordinator" },
    children: [
      {
        path: "comision",
        name: "commissionsCoordinator",
        meta: {
          title: "Autocom comisiones",
          descriptionLayout: "Listar comisiones",
        },
        component: () => import("@/views/user/coordinator/Commision.vue"),
      },
      {
        path: "comision/:commissionId/details",
        name: "commissionDetailsCoordinator",
        meta: {
          title: "Autocom comisiones",
          descriptionLayout: "Listar comisiones",
        },
        component: () => import("@/views/user/coordinator/Details.vue"),
      },
      // {
      //   path: "cambiar/contrasena",
      //   name: "changePassword4",
      //   meta: {
      //     title: "Autocom cambiar contraseña",
      //     descriptionLayout: "Cambiar contraseña",
      //   },
      //   component: () => import("@/views/user/ResetPassword.vue"),
      // },
    ],
  },
  // Gerente de cuenta
  {
    path: "/gerentecuenta",
    component: () => import("@/views/user/_Layout.vue"),
    meta: { isPublic: false },
    role: 4,
    redirect: { name: "commissionsAccountManager" },
    children: [
      {
        path: "comision",
        name: "commissionsAccountManager",
        meta: {
          title: "Autocom comisiones",
          descriptionLayout: "Listar comisiones",
        },
        component: () => import("@/views/user/accountManager/Commision.vue"),
      },
      {
        path: "comision/:commissionId/details",
        name: "commissionDetailsAccountManager",
        meta: {
          title: "Autocom comisiones",
          descriptionLayout: "Listar comisiones",
        },
        component: () => import("@/views/user/accountManager/Details.vue"),
      },
      // {
      //   path: "cambiar/contrasena",
      //   name: "changePassword5",
      //   meta: {
      //     title: "Autocom cambiar contraseña",
      //     descriptionLayout: "Cambiar contraseña",
      //   },
      //   component: () => import("@/views/user/ResetPassword.vue"),
      // },
    ],
  },
  //
  {
    path: "/",
    name: "Home",
    component: Home,
  },
];

const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  routes,
});

export default router;
