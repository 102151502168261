import Vue from "vue";
import App from "./App.vue";
import "./registerServiceWorker";
import "./bootstrap";
import router from "./router";
import store from "./store";
import vuetify from "./plugins/vuetify";
import Notifications from "vue-notification";
import { isLoggedIn } from "./utils/auth";

import ECharts from 'vue-echarts'
import { use } from 'echarts/core'

import "@mdi/font/css/materialdesignicons.css";

// import ECharts modules manually to reduce bundle size
import {
  CanvasRenderer
} from 'echarts/renderers'
import {
  BarChart
} from 'echarts/charts'
import {
  GridComponent,
  TooltipComponent
} from 'echarts/components'

use([
  CanvasRenderer,
  BarChart,
  GridComponent,
  TooltipComponent
]);

// import "echarts";

Vue.component('v-chart', ECharts)

Vue.use(Notifications);

Vue.config.productionTip = false;

router.beforeEach((to, from, next) => {
  if (!to.matched.some((record) => record.meta.isPublic) && !isLoggedIn()) {
    next({
      path: "/login",
      query: { redirect: to.fullPath },
    });
  } else {
    const nearestWithTitle = to.matched
      .slice()
      .reverse()
      .find((record) => record.meta && record.meta.title);

    if (nearestWithTitle) {
      document.title = to.meta.title;
    }
    if (to.meta.role != store.getters.getInfoUser && !to.name == 'unauthorized') {
      next({
        path: "/unauthorized",
      });
    }
    next();
  }
});

new Vue({
  router,
  store,
  vuetify,
  render: (h) => h(App),
}).$mount("#app");
